import { Atom, state } from ":mods";
import { LanguageSelectorViewProps } from "../model";
import { splitProps } from "solid-js";

export function LanguageSelector(props: LanguageSelectorViewProps) {
  const [local, others] = splitProps(props, ["darkIcon"]);
  const langs = ["EN", "العربية"];
  const $lang = state.create(langs[0]);

  function onClick(e: Event) {
    e.preventDefault();
    $lang.set((s) => (s === langs[0] ? langs[1] : langs[0]));
  }
  return (
    <div
      {...others}
      onClick={onClick}
      class={`relative flex flex-row w-100px h-fit items-center cursor-pointer space-x-2 ${others.class ?? ""}`}
    >
      <i class={` w-23px h-23px ${!local.darkIcon ? "icon-local-i18n:global-white" : "icon-local-i18n:global-dark"}`} />
      <p>{$lang.value}</p>
    </div>
  );
}
